/********** Template CSS **********/
:root {
    --primary: #2280c8;
    --secondary: #fff;
    --light: #fff;
    --dark: #000000;
    --darkGrey: #111;
    --lightGrey: #e3e3e3;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

.bl-1 {
    border-left: 1px solid var(--lightGrey);
}

.r-0 {
    right: 0;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 10px !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.pr-2 {
    padding-right: 20px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 10px;
}

.h-50vh {
    height: 50vh;
}

.h-30vh {
    height: 30vh;
}

.mh-60 {
    max-height: 600px !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

@media only screen and (max-width:587px) {
    .sm-display-none {
        display: none;
    }
}

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}


/*** Layout ***/
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    background: var(--secondary);
    transition: 0.5s;
    z-index: 999;
    box-shadow: -15px 0px 20px #111;
}

.content {
    margin-left: 250px;
    min-height: 100vh;
    background: #fff;
    transition: 0.5s;
}

@media (min-width: 992px) {
    .sidebar {
        margin-left: 0;
    }

    .sidebar.open {
        margin-left: -250px;
    }

    .content {
        width: calc(100% - 250px);
    }

    .content.open {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 991.98px) {
    .sidebar {
        margin-left: -250px;
    }

    .sidebar.open {
        margin-left: 0;
    }

    .content {
        width: 100%;
        margin-left: 0;
    }
}


/*** Navbar ***/
.nav-item a span {
    color: #2280c8;
}

.sidebar .navbar .navbar-nav .nav-link {
    padding: 7px 20px;
    color: var(--darkGrey);
    font-weight: 500;
    border-left: 3px solid var(--secondary);
    border-radius: 0 30px 30px 0;
    outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
    color: var(--light);
    background: var(--primary);
    border-color: var(--light);
}

.sidebar .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    color: var(--light);
    border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
    background: var(--secondary);
    color: var(--primary);
}

.sidebar .navbar .dropdown-toggle::after {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: .5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
    padding-left: 25px;
    border-radius: 0 30px 30px 0;
    color: var(--darkGrey);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
    background: var(--dark);
}

.content .navbar .navbar-nav .nav-link {
    margin-left: 25px;
    padding: 12px 0;
    color: var(--light);
    outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #2280c8;
    border-radius: 40px;
}

.sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
    color: #fff;
}

.content .navbar .dropdown-item {
    color: var(--darkGrey);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
    background: var(--primary);
}

.navbar {
    background-color: transparent !important;
}

.dropdown {
    cursor: pointer;
}

a.dropdown-item:hover h6,
a.dropdown-item:hover small,
a.dropdown-item:hover {
    color: var(--light) !important;
}

.content .navbar .dropdown-toggle::after {
    margin-left: 6px;
    vertical-align: middle;
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: .5s;
}

.content .navbar .dropdown-toggle.show::after {
    transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
    .content .navbar .navbar-nav .nav-link {
        margin-left: 15px;
    }
}


/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
    top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
    border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
    font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 10px;
    border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: var(--primary);
}


/*** Testimonial ***/
.progress .progress-bar {
    width: 0px;
    transition: 2s;
}


/*** Testimonial ***/
.testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border: 5px solid var(--primary);
    border-radius: 15px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    background: var(--dark);
    border-color: var(--primary);
}

.dp-add-button {
    font-size: 100px !important;

}

/** Rent.jsx Page CSS Start **/


/** Modal Start **/
.dp-services-background,
.dp-other-services-background,
.dp-reserved-background,
.dp-rent-detail-background,
.dp-selected-image-background,
.dp-cancel-rent-background,
.dp-add-cash-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.25);
    z-index: 9999;
}

body.dp-services-open .dp-services-background,
body.dp-other-services-open .dp-other-services-background,
body.dp-reserved-open .dp-reserved-background,
body.dp-rent-detail-open .dp-rent-detail-background,
body.dp-selected-image-open .dp-selected-image-background,
body.dp-cancel-rent-open .dp-cancel-rent-background,
body.dp-add-cash-open .dp-add-cash-background {
    visibility: visible;
    opacity: 1;
}

@keyframes modal-in {
    0% {
        translate: -50% 10%;
        scale: 0.5;
    }

    100% {
        opacity: 1;
        scale: 1;
        visibility: visible;
    }
}

.dp-services-modal,
.dp-services-background,
.dp-other-services-modal,
.dp-other-services-background,
.dp-reserved-modal,
.dp-reserved-background,
.dp-rent-detail-modal,
.dp-rent-detail-background,
.dp-selected-image-modal,
.dp-selected-image-background,
.dp-cancel-rent-modal,
.dp-cancel-rent-background,
.dp-add-cash-modal,
.dp-add-cash-background {
    transition: 0.5s;
}

.dp-services-modal,
.dp-other-services-modal,
.dp-reserved-modal,
.dp-rent-detail-modal,
.dp-selected-image-modal,
.dp-cancel-rent-modal,
.dp-add-cash-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    background: var(--light);
    color: var(--darkGrey);
    box-shadow: 0 0 15px #ccc;
    padding: 48px 40px;
    width: 50% !important;
    border-radius: 12px;
    translate: -50% -50%;
    scale: 1;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
}

@media only screen and (max-width:845px) {

    .dp-services-modal,
    .dp-other-services-modal,
    .dp-reserved-modal,
    .dp-rent-detail-modal,
    .dp-selected-image-modal,
    .dp-cancel-rent-modal,
    .dp-add-cash-modal {
        width: 90% !important;
    }
}

body.dp-services-open .dp-services-modal,
body.dp-other-services-open .dp-other-services-modal,
body.dp-reserved-open .dp-reserved-modal,
body.dp-rent-detail-open .dp-rent-detail-modal,
body.dp-selected-image-open .dp-selected-image-modal,
body.dp-cancel-rent-open .dp-cancel-rent-modal,
body.dp-add-cash-open .dp-add-cash-modal {
    opacity: 1;
    visibility: visible;
    animation: modal-in 0.5s;
}

.dp-services-modal-close,
.dp-other-services-modal-close,
.dp-reserved-modal-close,
.dp-rent-detail-modal-close,
.dp-selected-image-modal-close,
.dp-cancel-rent-modal-close,
.dp-add-cash-modal-close {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: var(--primary);
    color: var(--light);
    padding: 3px 10px;
    border-radius: 20px;
    cursor: pointer;
}

/** Modal End **/

/** Full Modal Start **/
.dp-services-full-background,
.dp-technic-full-background,
.dp-security-full-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.25);
    z-index: 9999;
}

body.dp-services-full-open .dp-services-full-background,
body.dp-technic-full-open .dp-technic-full-background,
body.dp-security-full-open .dp-security-full-background {
    visibility: visible;
    opacity: 1;
}

@keyframes modal-in {
    0% {
        translate: -50% 10%;
        scale: 0.5;
    }

    100% {
        opacity: 1;
        scale: 1;
        visibility: visible;
    }
}

.dp-services-full-modal,
.dp-services-full-background,
.dp-technic-full-modal,
.dp-technic-full-background,
.dp-security-full-modal,
.dp-security-full-background {
    transition: 0.5s;
}

.dp-services-full-modal,
.dp-technic-full-modal,
.dp-security-full-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    background: var(--light);
    color: var(--darkGrey);
    box-shadow: 0 0 15px #ccc;
    padding: 48px 40px;
    width: 70% !important;
    max-height: 80vh;
    border-radius: 12px;
    translate: -50% -50%;
    scale: 1;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
}

body.dp-services-full-open .dp-services-full-modal,
body.dp-technic-full-open .dp-technic-full-modal,
body.dp-security-full-open .dp-security-full-modal {
    opacity: 1;
    visibility: visible;
    animation: modal-in 0.5s;
}

.dp-services-full-modal-close,
.dp-technic-full-modal-close,
.dp-security-full-modal-close {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: var(--primary);
    color: var(--light);
    padding: 3px 10px;
    border-radius: 20px;
    cursor: pointer;
}

/** Full Modal End **/

/** Rent.jsx Page CSS End **/

.tab-pane {
    width: 100%;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.dp-feature-sticky {
    top: 64px !important;
    padding: 10px !important;
    box-shadow: 0px 5px 5px -2px #CCC;
    background-color: var(--light);
    display: flex;
    align-items: center;
}

.dp-comment a:hover,
.dp-secondary-link {
    cursor: pointer;
    color: var(--primary) !important;
}

.edit-title:hover .edit-icon {
    opacity: 1;
}

.edit-icon {
    margin-left: 5px;
    opacity: 0;
}

.working-hours-table tr td:nth-child(2) {
    padding-left: 10px;
    padding-right: 10px;
}

.dp-executive-delete {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: var(--primary);
    color: var(--light);
    padding: 3px 10px;
    border-radius: 20px;
    cursor: pointer;
    opacity: 0;
    transition: .5s;
    animation: ease-down;

}

.dp-executive-card:hover .dp-executive-delete {
    opacity: 1 !important;
}

.dp-executive-delete i:nth-child(2) {
    margin-left: 10px;
}

.dg-accordion-item-header h3 button {
    background-color: #fff !important;
    border: none !important;
    font-size: 16px !important;
    color: #2280c8 !important;
}

.form-check i {
    cursor: pointer !important;
}

.text-disable {
    color: #999;
}

.executive-languages span {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
}

.dp-questions {
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: 100%;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.slick-track {
    width: 1344px !important;
    display: inline-flex !important;
}

/* 
.slick-slide {
    width: 25% !important;
    margin: 0 10px;
    cursor: pointer !important;
} */

.slick-slide img {
    height: 150px;
    object-fit: contain;
    padding: 10px !important;
}

.dp-big-image {
    width: 100% !important;
    height: 450px;
    object-fit: contain;
}

.dp-big-image-button {
    cursor: pointer;
    color: #000;
    display: none;
    right: 10px;
    top: 10px;
}

.dp-big-image-canvas:hover .dp-big-image-button {
    display: block;
}

.slick-prev:before,
.slick-next:before {
    color: #000 !important;
}

.slick-prev {
    left: 0 !important;
    z-index: 1;
}

.slick-next {
    right: 0 !important;
    z-index: 1;
}

.result-map {
    width: 100%;
    min-height: 70vh;
}

.result-list {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 15px 0;
}

.result-list>* {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
}

.result-contact-card {
    bottom: 20px;
    left: 20px;
    width: 50%;
    height: 150px;
    background-color: #fff;
}

.result-item-active {
    box-shadow: 0px 0px 3px #2280c8 !important;
    border: 1px solid #2280c8;
}

.iyzico-link:hover {
    color: #2280c8 !important;
}