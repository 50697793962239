span.notification {
    /* color: white !important;
    font-size: 10px;
    background-color: red;
    padding: 2px !important;
    margin: 0 !important;
    height: 18px !important;
    align-items: start !important;
    border-radius: 45px; */
    color: white !important;
    font-size: 10px;
    background-color: red;
    margin-left: 2px !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    text-align: center;
    align-self: center;
}